exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-impressum-jsx": () => import("./../../../src/templates/Impressum.jsx" /* webpackChunkName: "component---src-templates-impressum-jsx" */),
  "component---src-templates-investors-jsx": () => import("./../../../src/templates/Investors.jsx" /* webpackChunkName: "component---src-templates-investors-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/Privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-support-jsx": () => import("./../../../src/templates/Support.jsx" /* webpackChunkName: "component---src-templates-support-jsx" */),
  "component---src-templates-tax-and-social-security-contribution-obligation-jsx": () => import("./../../../src/templates/TaxAndSocialSecurityContributionObligation.jsx" /* webpackChunkName: "component---src-templates-tax-and-social-security-contribution-obligation-jsx" */),
  "component---src-templates-terms-jsx": () => import("./../../../src/templates/Terms.jsx" /* webpackChunkName: "component---src-templates-terms-jsx" */)
}

