import { extendTheme, baseTheme as itsBaseTheme } from '@chakra-ui/react';

const theme = {
  config: {
    initialColorMode: 'light',
  },
  styles: {
    global: () => ({
      body: {
        bg: '',
        text: '',
        color: '',
      },
    }),
  },
};

export default extendTheme(theme);

export const baseTheme = itsBaseTheme
